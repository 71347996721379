<template>
  <div>
    <VRow>
      <VCol md="2">
        <h4>Название</h4>
      </VCol>
      <VCol md="6">
        <VTextField
          v-model="content.title"
          solo
          outlined
          flat
          hide-details="auto"
          :disabled="loadingVideo"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol md="2">
        <h4>Содержимое</h4>
      </VCol>
      <VCol md="6">
        <VTextarea
          v-model="content.body"
          solo
          outlined
          flat
          hide-details="auto"
          :disabled="loadingVideo"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol md="2">
        <h4>Описание</h4>
      </VCol>
      <VCol md="6">
        <VTextarea
          v-model="description"
          solo
          outlined
          flat
          hide-details="auto"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol md="2">
        <h4>Файл</h4>
      </VCol>
      <VCol md="6">
        <VFileInput
          v-model="file"
          solo
          outlined
          flat
          show-size
          hide-details="auto"
          :disabled="loadingVideo"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol md="2">
        <h4>Copyright</h4>
      </VCol>
      <VCol md="6">
        <VAutocomplete
          v-model="copyrightId"
          :items="copyrights"
          :filter="customFilter"
          item-text="name"
          item-value="id"
          label="Copyright"
          name="copyrightId"
          persistent-hint
          hint="Выберите copyright"
        >
          <template #selection="{ item }">
            <VListItemContent>
              <VListItemTitle v-text="item.name" />
              <VListItemSubtitle v-text="item.id" />
            </VListItemContent>
          </template>

          <template #item="{ item }">
            <VListItemContent>
              <VListItemTitle v-text="item.name" />
              <VListItemSubtitle v-text="item.id" />
            </VListItemContent>
          </template>
        </VAutocomplete>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        md="6"
        offset-md="2"
      >
        <VBtn
          color="primary"
          :disabled="loadingVideo"
          :loading="loadingVideo"
          @click="saveContentItem"
        >
          Сохранить
        </VBtn>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { contentTypes } from './courses/common';

export default {
  name: 'ContentStorageCreateVideo',
  data() {
    return {
      content: {
        title: '',
        body: '',
      },
      copyrights: [],
      copyrightId: '',
      description: '',
      pagination: {
        offset: 0,
        limit: 0,
      },
      file: null,
      loadingVideo: false,
    };
  },

  async created() {
    await this.fetchCopyrights();
  },

  methods: {
    async fetchCopyrights() {
      try {
        const { pagination } = this;
        const data = { pagination };

        const { copyrights } = await this.$di.api.ContentStorage.copyrightIndex({ data });

        this.copyrights = copyrights;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },

    saveContentItem() {
      const content = {
        contentType: contentTypes.videoHLS,
        content: this.content,
        description: this.description,
        extension: this.file.name.split('.').pop(),
        mimetype: this.file.type,
      };

      this.$di.api.ContentStorage
        .contentItemCreate(content)
        .then(this.cloudUpload)
        .catch(this.$di.notify.errorHandler);
    },
    resetContentItem() {
      this.content = {
        title: '',
        body: '',
      };

      this.file = null;
      this.description = '';
    },
    cloudUpload(meta) {
      const { id, videoUploadUrl } = meta;

      /**
       * Note: Content-Type всегда MIME тип файла
       * @see https://cloud.yandex.ru/docs/storage/concepts/presigned-post-forms#form-fields Описание полей формы
       * */
      const headers = {
        'Content-Type': this.file.type,
      };

      const config = {
        method: 'PUT',
        body: this.file,
        headers,
      };

      this.loadingVideo = true;

      /**
       * Note: Запрос не работает из-за CORS.
       * Проверить работоспособность запроса можно отключив CORS в браузере или на stage/production окружении
       * */
      /** Note: Здесь используется простой fetch */
      this.$di.api.CloudStorage
        .fileStorageUpload(videoUploadUrl, config)
        .then(() => this.confirmUpload(id))
        .catch((error) => {
          this.$di.notify.snackError(error);
        })
        .finally(() => {
          this.loadingVideo = false;
        });
    },
    confirmUpload(id) {
      const { copyrightId } = this;
      const data = { id, copyrightId };
      this.$di.api.ContentStorage
        .contentItemVideoUploaded(data)
        .then(() => {
          this.resetContentItem();
          this.$emit('created');
          this.$di.notify.snackSuccess('Видео отправлено на транскодинг');
          this.copyrightId = '';
        })
        .catch(this.$di.notify.errorHandler);
    },

    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
