var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VCard',[_c('VContainer',{attrs:{"fluid":""}},[_c('VRow',[_c('VCol',[_c('h1',[_vm._v("Контент")])])],1),_c('VRow',[_c('VCol',[_c('h3',[_vm._v("Создание контента")])])],1),_c('VRow',[_c('VCol',[_c('VRadioGroup',{attrs:{"row":""},model:{value:(_vm.selectedContentType),callback:function ($$v) {_vm.selectedContentType=$$v},expression:"selectedContentType"}},[_c('VRadio',{attrs:{"label":"Текстовый","value":_vm.contentTypes.textPlain}}),_c('VRadio',{attrs:{"label":"Видео","value":_vm.contentTypes.videoHLS}}),_c('VRadio',{attrs:{"label":"HTML","value":_vm.contentTypes.textHTML}})],1)],1)],1),_c('VRow',[_c('VCol',[_c(_vm.componentByContentType,{tag:"Component",on:{"created":_vm.resetPaginationAndReloadContent}})],1)],1)],1)],1)],1)],1),_c('VRow',[_c('VCol',[_c('VCard',[_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.pagination.total,"loading":_vm.contentLoading},on:{"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(item.content.title)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(item.contentType)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.contentTypes.videoHLS === item.contentType)?[(_vm.CONTENT_STATUS.STATUS_ERROR === item.status)?_c('VBtn',{attrs:{"depressed":"","icon":"","color":"warning"},on:{"click":function($event){return _vm.reuploadContent(item.id)}}},[_c('VIcon',[_vm._v("fal fa-redo")])],1):_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.status || 'Нет статуса')+" ")])]:_vm._e()]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(item.content.body)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VBtn',{attrs:{"icon":"","color":"warning","to":{
                name : _vm.Names.R_CONTENT_STORAGE_CONTENT_EDIT,
                params : {
                  contentId : item.id
                }
              }}},[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }