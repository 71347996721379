<template>
  <div>
    <VRow>
      <VCol md="2">
        <h4>Название</h4>
      </VCol>
      <VCol md="6">
        <VTextField
          v-model="content.title"
          solo
          outlined
          flat
          hide-details="auto"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol md="2">
        <h4>Содержимое</h4>
      </VCol>
      <VCol md="6">
        <VTextarea
          v-model="content.body"
          solo
          outlined
          flat
          hide-details="auto"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol md="2">
        <h4>Описание</h4>
      </VCol>
      <VCol md="6">
        <VTextarea
          v-model="description"
          solo
          outlined
          flat
          hide-details="auto"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol md="2" />
      <VCol md="6">
        <VBtn
          color="primary"
          @click="saveContentItem"
        >
          Сохранить
        </VBtn>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { contentTypes } from './courses/common';

export default {
  name: 'ContentStorageCreateHtml',
  data() {
    return {
      content: {
        title: '',
        body: '',
      },
      description: '',
    };
  },
  methods: {
    saveContentItem() {
      const content = {
        contentType: contentTypes.textHTML,
        content: this.content,
        description: this.description,
      };

      this.$di.api.ContentStorage.contentItemCreate(content)
        .then(() => {
          this.resetContentItem();
          this.$emit('created');
        })
        .catch(this.$di.notify.errorHandler);
    },
    resetContentItem() {
      this.description = '';
      this.content = {
        title: '',
        body: '',
      };
    },
  },
};
</script>
