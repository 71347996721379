<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VContainer
            fluid
          >
            <VRow>
              <VCol>
                <h1>Контент</h1>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <h3>Создание контента</h3>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VRadioGroup
                  v-model="selectedContentType"
                  row
                >
                  <VRadio
                    label="Текстовый"
                    :value="contentTypes.textPlain"
                  />
                  <VRadio
                    label="Видео"
                    :value="contentTypes.videoHLS"
                  />
                  <VRadio
                    label="HTML"
                    :value="contentTypes.textHTML"
                  />
                </VRadioGroup>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <Component
                  :is="componentByContentType"
                  @created="resetPaginationAndReloadContent"
                />
              </VCol>
            </VRow>
          </VContainer>
        </VCard>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VCard>
          <VDataTable
            :headers="headers"
            :items="items"
            :server-items-length="pagination.total"
            :loading="contentLoading"
            @pagination="handlePagination"
          >
            <template #item.name="{ item }">
              <h3 class="title">
                {{ item.content.title }}
              </h3>
              <p class="mb-0">
                {{ item.id }}
              </p>
            </template>

            <template #item.type="{ item }">
              <h4>
                {{ item.contentType }}
              </h4>
            </template>

            <template #item.status="{ item }">
              <template v-if="contentTypes.videoHLS === item.contentType">
                <VBtn
                  v-if="CONTENT_STATUS.STATUS_ERROR === item.status"
                  depressed
                  icon
                  color="warning"
                  @click="reuploadContent(item.id)"
                >
                  <VIcon>fal fa-redo</VIcon>
                </VBtn>
                <p
                  v-else
                  class="mb-0"
                >
                  {{ item.status || 'Нет статуса' }}
                </p>
              </template>
            </template>

            <template #item.content="{ item }">
              <h4>
                {{ item.content.body }}
              </h4>
            </template>

            <template #item.actions="{ item }">
              <VBtn
                icon
                color="warning"
                :to="{
                  name : Names.R_CONTENT_STORAGE_CONTENT_EDIT,
                  params : {
                    contentId : item.id
                  }
                }"
              >
                <VIcon small>
                  fal fa-edit
                </VIcon>
              </VBtn>
            </template>
          </VDataTable>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { contentTypes, CONTENT_STATUS } from '../../../components/contentStorage/courses/common';
import ContentStorageCreateText from '../../../components/contentStorage/ContentStorageCreateText.vue';
import ContentStorageCreateVideo from '../../../components/contentStorage/ContentStorageCreateVideo.vue';
import ContentStorageCreateHtml from '../../../components/contentStorage/ContentStorageCreateHtml.vue';

export default {
  name: 'ContentStorage',
  inject: ['Names'],
  data() {
    return {
      contentTypes,
      CONTENT_STATUS,
      selectedContentType: contentTypes.textPlain,
      contentLoading: false,
      items: [],
      pagination: {
        offset: 0,
        limit: 0,
        total: 0,
        /** Note: page нужен для перезагрузки текущей страницы */
        page: 0,
      },
      defaultPagination: { itemsLength: 0, itemsPerPage: 10, page: 1 },
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Тип контента',
          align: 'left',
          sortable: false,
          value: 'type',
        },
        {
          text: 'Статус',
          align: 'left',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Контент',
          align: 'left',
          sortable: false,
          value: 'content',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    componentByContentType() {
      switch (this.selectedContentType) {
        case contentTypes.textHTML:
          return ContentStorageCreateHtml;
        case contentTypes.videoHLS:
          return ContentStorageCreateVideo;
        case contentTypes.textPlain:
        default:
          return ContentStorageCreateText;
      }
    },
  },
  methods: {
    async handlePagination({ itemsLength, itemsPerPage, page }) {
      this.contentLoading = true;

      const limit = itemsPerPage === -1 ? itemsLength : itemsPerPage;
      const offset = page > 0 ? (page - 1) * limit : 0;

      this.$di.api.ContentStorage
        .contentItemOrphaned({ pagination: { limit, offset } })
        .then((response) => {
          if (response && response.items && Array.isArray(response.items)) {
            this.items = response.items;
          }

          this.pagination = {
            total: response.total || 0,
            offset: response.pagination.offset || 0,
            limit: response.pagination.limit || 0,
            page,
          };
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.contentLoading = false;
        });
    },
    /** Note: Вспомогательный метод для дочерних компонентов */
    resetPaginationAndReloadContent(pagination = this.defaultPagination) {
      this.handlePagination(pagination);
    },
    reuploadContent(id) {
      this.$di.api.ContentStorage
        .contentItemVideoUploaded({ id })
        .then(() => {
          const pagination = {
            itemsLength: this.pagination.total,
            itemsPerPage: this.pagination.limit,
            page: this.pagination.page,
          };

          this.handlePagination(pagination);
        })
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
